import { createSlice, current } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

interface InitialState {
  General: {};
  Settings: {};
  Candidate: [];
  Test: {
    TestType: string;
    TestQuestions: any[];
  }[];
  test_id: string;
}

const formData = createSlice({
  name: "form",

  initialState: {
    General: {},
    Test: [],
    Settings: {},
    Candidate: [],
    test_id: "",
  } as InitialState,

  reducers: {
    addGeneral(state, action) {
      state.General = action.payload;
    },
    addTestType(state, action) {
      // state.Test.map((item) => (item.TestType = action.payload));
      state.Test.push({ TestType: action.payload, TestQuestions: [] });
    },
    addTestId(state, action) {
      state.test_id = action.payload;
    },
    addTestQuestions(state, action) {
      const unique_id = uuid();

      // Get first 8 characters using slice
      const small_id = unique_id.slice(0, 8);

      action.payload.data["id"] = action.payload.type + "_" + small_id;
      
      state.Test.map((item: any) => {
        return (
          item.TestType === action.payload.type &&
          item.TestQuestions.push(action.payload.data)
        );
      });
      // ONLY FOR TEST LIBRARY
      if(state.Test.length===0){
        state.Test.push({TestType:'test_library',TestQuestions:[action.payload.data]})
      }
    },
    removeTestType(state, action) {
      state.Test.map((item: any, index: number) => {
        if (item.TestType === action.payload) {
          state.Test.splice(index, 1);
        }
      });
    },
    removeTestQuestion(state, action) {
      state.Test.map((item: any) => {
        return item.TestQuestions.filter((el: any, index: number) => {
          if (el.id === action.payload) {
            item.TestQuestions.splice(index, 1);
          }
        });
      });
    },
    editTestQuestion(state, action) {
      state.Test.map((item: any) => {
        if (item.TestType === action.payload.type) {
          item.TestQuestions.map((val: any, index: number) => {
            if (val.id === action.payload.id) {
              action.payload.data["id"] = val.id;
              item.TestQuestions[index] = action.payload.data;
            }
          });
        }
      });
    },
    addSettings(state, action) {
      state.Settings = action.payload;
    },
    addCandidate(state, action) {
      state.Candidate = action.payload;
    },
    resetTest(state, action) {
      (state.General = {}),
        (state.Settings = {}),
        (state.Test = []),
        (state.test_id = ""),
        (state.Candidate = []);
    },
  },
});
export default formData.reducer;
export const {
  addGeneral,
  addTestType,
  addTestQuestions,
  removeTestQuestion,
  editTestQuestion,
  addSettings,
  addCandidate,
  resetTest,
  addTestId,
  removeTestType,
} = formData.actions;
