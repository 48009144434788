import * as React from "react"
const SvgComponent = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={57}
    fill="none"
    {...props}
  >
    <g
      
      stroke={props.stroke ?? "#292D32"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.621}
    >
      <path d="M20.966 40.106V26.128l-4.66 4.66M20.967 26.128l4.659 4.66" />
      <path d="M51.252 23.799v11.648c0 11.648-4.66 16.307-16.307 16.307H20.967c-11.648 0-16.307-4.66-16.307-16.307V21.469c0-11.648 4.66-16.307 16.307-16.307h11.648" />
      <path d="M51.252 23.799h-9.318c-6.99 0-9.319-2.33-9.319-9.319V5.162L51.252 23.8Z" />
    </g>
  </svg>
)
export default SvgComponent