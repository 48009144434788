import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

const inviteTestPopup = createSlice({
  name: "inviteTestPopup",
  initialState: {isActive:false, testData: {}},
  reducers: {
    handleInviteTestPopup(state, action) {
      state = action.payload
      return state
    },
 
  },
});
export default inviteTestPopup.reducer;

export const {
    handleInviteTestPopup
} = inviteTestPopup.actions;
