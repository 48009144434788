import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { UserDetailsType } from "model/types";

const userData = createSlice({
  name: "userData",
  initialState:{} as UserDetailsType,
  reducers: {
    addUserData(state, action: PayloadAction<UserDetailsType>) {
      state = Object.assign(state,action.payload)
      return state
    },
    resetUserData(state) {
      state = {} as UserDetailsType
      return state;
    },
  },
});
export default userData.reducer;

export const {
  addUserData,
  resetUserData
} = userData.actions;
