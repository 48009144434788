import { Tuple, combineReducers, configureStore } from '@reduxjs/toolkit'
import formData from './createTestReducer'
import userData from './userData';
import addCandidatePopup from './addCandidatePopup';
import { persistReducer, persistStore } from 'redux-persist';

import createWebStorage from 'redux-persist/es/storage/createWebStorage';
import inviteTestPopup from './inviteTestPopup';
import steps from './steps';

const createNoopStorage = () => {
  return {
     getItem(_key: any) {
        return Promise.resolve(null);
     },
     setItem(_key: any, value: any) {
        return Promise.resolve(value);
     },
     removeItem(_key: any) {
        return Promise.resolve();
     },
  };
};

const storage = typeof window !== 'undefined' ?createWebStorage('local'):createNoopStorage() 

const persistConfig = {
  key:'root',
  storage,
  whitelist:['userData']
}

const rootReducer = combineReducers({formData,userData,addCandidatePopup,inviteTestPopup, steps})

const persistedReducer = persistReducer(persistConfig,rootReducer)

export const store = configureStore({
  reducer:persistedReducer,
  devTools:process.env.NODE_ENV !== 'production',
  middleware:(getDefaultMiddleware) => getDefaultMiddleware({
    thunk:true,
    serializableCheck:false
  })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;