import * as React from "react";
const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
    style={{marginTop: "-3px"}}
  >
    <rect width={19.356} height={19.356} fill="#0080FF" rx={9.678} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.678 6.295v6.765m3.382-3.382H6.295"
    />
  </svg>
);
export default SvgComponent;
