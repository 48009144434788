import * as React from "react"
const SvgComponent = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <g
      stroke="grey"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path d="M19.25 5.482a93.226 93.226 0 0 0-9.185-.459c-1.815 0-3.63.092-5.445.275l-1.87.184M7.792 4.556l.202-1.201c.146-.87.256-1.522 1.805-1.522h2.402c1.55 0 1.668.688 1.806 1.531l.202 1.192M17.279 8.378l-.596 9.231c-.1 1.44-.183 2.558-2.74 2.558H8.056c-2.557 0-2.64-1.119-2.74-2.558L4.72 8.38M9.469 15.125h3.052M8.708 11.458h4.583" />
    </g>
  </svg>
)
export default SvgComponent