import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path

      stroke="#fff"

    
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.25}
      d="m15.242 2.403-13.24 11.53M2.524 1.91 14.72 14.426"
    />
  </svg>
);
export default SvgComponent;
