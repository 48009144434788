exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-dashboard-candidate-details-index-tsx": () => import("./../../../src/pages/dashboard/candidate-details/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-candidate-details-index-tsx" */),
  "component---src-pages-dashboard-candidate-index-tsx": () => import("./../../../src/pages/dashboard/candidate/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-candidate-index-tsx" */),
  "component---src-pages-dashboard-feedback-index-tsx": () => import("./../../../src/pages/dashboard/feedback/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-feedback-index-tsx" */),
  "component---src-pages-dashboard-help-index-tsx": () => import("./../../../src/pages/dashboard/help/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-help-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-invite-index-tsx": () => import("./../../../src/pages/dashboard/invite/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-invite-index-tsx" */),
  "component---src-pages-dashboard-jobs-index-tsx": () => import("./../../../src/pages/dashboard/jobs/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-jobs-index-tsx" */),
  "component---src-pages-dashboard-plans-and-billing-index-tsx": () => import("./../../../src/pages/dashboard/plans-and-billing/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-plans-and-billing-index-tsx" */),
  "component---src-pages-dashboard-popup-modal-index-tsx": () => import("./../../../src/pages/dashboard/popup-modal/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-popup-modal-index-tsx" */),
  "component---src-pages-dashboard-profile-index-tsx": () => import("./../../../src/pages/dashboard/profile/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-profile-index-tsx" */),
  "component---src-pages-dashboard-resume-index-tsx": () => import("./../../../src/pages/dashboard/resume/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-resume-index-tsx" */),
  "component---src-pages-dashboard-resume-view-tsx": () => import("./../../../src/pages/dashboard/resume/view.tsx" /* webpackChunkName: "component---src-pages-dashboard-resume-view-tsx" */),
  "component---src-pages-dashboard-sheet-index-tsx": () => import("./../../../src/pages/dashboard/sheet/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-sheet-index-tsx" */),
  "component---src-pages-dashboard-template-index-tsx": () => import("./../../../src/pages/dashboard/template/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-template-index-tsx" */),
  "component---src-pages-dashboard-test-library-create-library-tsx": () => import("./../../../src/pages/dashboard/test-library/create-library.tsx" /* webpackChunkName: "component---src-pages-dashboard-test-library-create-library-tsx" */),
  "component---src-pages-dashboard-test-library-index-tsx": () => import("./../../../src/pages/dashboard/test-library/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-test-library-index-tsx" */),
  "component---src-pages-dashboard-test-library-preview-tsx": () => import("./../../../src/pages/dashboard/test-library/preview.tsx" /* webpackChunkName: "component---src-pages-dashboard-test-library-preview-tsx" */),
  "component---src-pages-dashboard-test-sheet-index-tsx": () => import("./../../../src/pages/dashboard/test-sheet/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-test-sheet-index-tsx" */),
  "component---src-pages-dashboard-tests-index-tsx": () => import("./../../../src/pages/dashboard/tests/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-tests-index-tsx" */),
  "component---src-pages-employee-tsx": () => import("./../../../src/pages/employee.tsx" /* webpackChunkName: "component---src-pages-employee-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-forgetpasswordsuccess-tsx": () => import("./../../../src/pages/forgetpasswordsuccess.tsx" /* webpackChunkName: "component---src-pages-forgetpasswordsuccess-tsx" */),
  "component---src-pages-forgotpassword-tsx": () => import("./../../../src/pages/forgotpassword.tsx" /* webpackChunkName: "component---src-pages-forgotpassword-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-reset-password-alert-tsx": () => import("./../../../src/pages/reset-password-alert.tsx" /* webpackChunkName: "component---src-pages-reset-password-alert-tsx" */),
  "component---src-pages-resetpassword-tsx": () => import("./../../../src/pages/resetpassword.tsx" /* webpackChunkName: "component---src-pages-resetpassword-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-solution-tsx": () => import("./../../../src/pages/solution.tsx" /* webpackChunkName: "component---src-pages-solution-tsx" */),
  "component---src-pages-termandcondition-tsx": () => import("./../../../src/pages/termandcondition.tsx" /* webpackChunkName: "component---src-pages-termandcondition-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-categories-post-tsx": () => import("./../../../src/templates/categories-post.tsx" /* webpackChunkName: "component---src-templates-categories-post-tsx" */)
}

