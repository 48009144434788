import { Dialog, DialogTitle, Fab, useMediaQuery, useTheme } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import React, { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { ArrowLeft } from "../../svg";

interface IProps {
  children: ReactNode;
  icon?: ReactNode;
  onFabClick?: () => void;
  onClose: () => void ;
  open: boolean;
  title: string;
  style?: { width: number };
  large?: boolean;
  disabledIcon?: boolean;
  showOnlyOnMobile?:boolean
}

const StepPopup = ({
  onClose,
  open,
  title,
  style,
  icon = <ArrowLeft />,
  children,
  large,
  showOnlyOnMobile,
  disabledIcon,
}: IProps) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(()=>{
    if(showOnlyOnMobile){
      setDialogOpen(matches)
    }
  },[showOnlyOnMobile])

  return (
    <Dialog
      onClose={onClose}
      open={showOnlyOnMobile?dialogOpen:open}

      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: style ? style.width : 700,
          background: "transparent",
          padding: {
            md: "35px",
            xs: "25px",
          },
          boxShadow: "none",
          margin: {
            md: "35px",
            xs: "10px",
          },
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        {!disabledIcon && (
          <Fab
            color="primary"
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              left: "-25px",
              top: "-25px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {icon}
          </Fab>
        )}
        <Box
          sx={{
            background: "#FFFFFF",
            height: "100%",
            borderRadius: "10px",
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, pl: 6 }}
            fontSize={24}
            fontWeight={700}
          >
            {title}
          </DialogTitle>
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};

export default StepPopup;
