import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
<svg xmlns="http://www.w3.org/2000/svg" width="67" height="66" viewBox="0 0 67 66" fill="none">
<g clip-path="url(#clip0_4265_5487)">
<path d="M14.2871 20.3683L32.8005 11.6278H27.57L19.6553 4.88311C19.3112 4.53903 18.8295 4.40137 18.3476 4.40137H5.34002C4.16998 4.40137 3.27539 5.29609 3.27539 6.466V20.3683H14.2871Z" fill="#0080FF"/>
<path d="M57.6507 20.3685H63.2943V13.6926C63.2943 12.5225 62.3995 11.6279 61.2296 11.6279H53.5215L57.6507 20.3685Z" fill="#0080FF"/>
<path d="M50.9741 5.36621C50.4234 4.19617 48.9782 3.64567 47.7394 4.26507L12.7773 20.7826H58.2005L50.9741 5.36621Z" fill="#D4E1F4"/>
<path d="M65.4956 20.6448C65.0826 20.2318 64.5322 20.0254 63.9815 20.0254H63.2933H57.6498H14.2913H3.27962H2.59146C2.04082 20.0254 1.49031 20.2318 1.07733 20.6448C0.664355 21.0578 0.457932 21.6083 0.526696 22.1589L2.59132 59.5986C2.66009 60.6997 3.55481 61.5257 4.65595 61.5257H61.9167C63.0179 61.5257 63.9126 60.6999 63.9814 59.5986L66.0461 22.1589C66.0461 21.6083 65.8397 21.0576 65.4956 20.6448Z" fill="#0080FF"/>
</g>
<defs>
<clipPath id="clip0_4265_5487">
<rect width="65.5325" height="65.5325" fill="white" transform="translate(0.515625)"/>
</clipPath>
</defs>
</svg>
)
export default SvgComponent
