import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={57}
    fill="none"
    {...props}
  >
    <g
      stroke="rgba(0,0,0,0.26)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.621}
    >
      <path d="m18.638 23.799-4.66 4.659 4.66 4.66M37.274 23.799l4.66 4.659-4.66 4.66" />
      <path d="M27.955 51.754c12.866 0 23.296-10.43 23.296-23.296S40.82 5.162 27.955 5.162 4.66 15.592 4.66 28.458s10.43 23.296 23.296 23.296ZM30.285 23.03l-4.659 10.856" />
    </g>
  </svg>
);
export default SvgComponent;
