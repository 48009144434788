import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
<path d="M49.9979 58.2192C54.8185 58.2192 58.7264 54.3331 58.7264 49.5393C58.7264 44.7455 54.8185 40.8594 49.9979 40.8594C45.1774 40.8594 41.2695 44.7455 41.2695 49.5393C41.2695 54.3331 45.1774 58.2192 49.9979 58.2192Z" fill="#61DAFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.95916 44.9725C6.97979 46.7494 6.32918 48.2987 6.32918 49.5396C6.32918 50.7806 6.97979 52.3299 8.95916 54.1068C10.9372 55.8825 13.978 57.6265 18.0057 59.1558C26.0387 62.2059 37.3496 64.1583 50 64.1583C62.6504 64.1583 73.9613 62.2059 81.9943 59.1558C86.022 57.6265 89.0628 55.8825 91.0408 54.1068C93.0202 52.3299 93.6708 50.7806 93.6708 49.5396C93.6708 48.2987 93.0202 46.7494 91.0408 44.9725C89.0628 43.1968 86.022 41.4528 81.9943 39.9235C73.9613 36.8734 62.6504 34.921 50 34.921C37.3496 34.921 26.0387 36.8734 18.0057 39.9235C13.978 41.4528 10.9372 43.1968 8.95916 44.9725ZM15.759 34.0065C24.6771 30.6203 36.7839 28.5918 50 28.5918C63.2161 28.5918 75.3229 30.6203 84.241 34.0065C88.6889 35.6953 92.5069 37.7833 95.2689 40.2627C98.0295 42.7409 100 45.8699 100 49.5396C100 53.2094 98.0295 56.3384 95.2689 58.8166C92.5069 61.296 88.6889 63.384 84.241 65.0728C75.3229 68.459 63.2161 70.4875 50 70.4875C36.7839 70.4875 24.6771 68.459 15.759 65.0728C11.3111 63.384 7.4931 61.296 4.73114 58.8166C1.97051 56.3384 0 53.2094 0 49.5396C0 45.8699 1.97051 42.7409 4.73114 40.2627C7.4931 37.7833 11.3111 35.6953 15.759 34.0065Z" fill="#61DAFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.4805 11.9181C30.9466 11.103 29.2634 11.3132 28.1693 11.9414C27.0751 12.5696 26.0504 13.9141 25.4934 16.5039C24.9363 19.0939 24.938 22.5823 25.6195 26.8129C26.9791 35.2515 30.9324 45.9644 37.2557 56.8559C43.579 67.7473 50.9319 76.5084 57.6009 81.8985C60.9444 84.6008 63.9815 86.3464 66.5156 87.1617C69.0495 87.9769 70.7327 87.7667 71.8269 87.1384C72.921 86.5102 73.9457 85.1658 74.5027 82.5759C75.0598 79.9859 75.0582 76.4975 74.3766 72.2669C73.0171 63.8284 69.0637 53.1154 62.7404 42.224C56.4171 31.3325 49.0643 22.5714 42.3952 17.1813C39.0517 14.479 36.0146 12.7334 33.4805 11.9181ZM46.3849 12.2811C53.7965 18.2714 61.6193 27.6874 68.2293 39.0726C74.8392 50.4577 79.1279 61.9029 80.6388 71.281C81.3924 75.9587 81.4831 80.2933 80.7046 83.913C79.926 87.5329 78.1871 90.7849 75.0047 92.612C71.8223 94.4391 68.1208 94.3107 64.5791 93.1713C61.0375 92.032 57.3081 89.7866 53.6112 86.7987C46.1996 80.8084 38.3768 71.3924 31.7668 60.0072C25.1569 48.6221 20.8682 37.1769 19.3573 27.7988C18.6037 23.1211 18.513 18.7865 19.2915 15.1668C20.0701 11.5469 21.8091 8.29495 24.9914 6.46781C28.1738 4.64067 31.8753 4.76908 35.4171 5.90849C38.9586 7.04784 42.688 9.29321 46.3849 12.2811Z" fill="#61DAFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.5051 16.5033C73.9481 13.9135 72.9234 12.569 71.8292 11.9408C70.7351 11.3126 69.0519 11.1024 66.518 11.9176C63.9839 12.7328 60.9468 14.4785 57.6033 17.1807C50.9342 22.5708 43.5814 31.332 37.2581 42.2234C30.9348 53.1148 26.9815 63.8278 25.6219 72.2663C24.9404 76.4969 24.9387 79.9854 25.4958 82.5754C26.0528 85.1652 27.0775 86.5097 28.1717 87.1379C29.2658 87.7661 30.949 87.9763 33.4829 87.1611C36.017 86.3459 39.0541 84.6003 42.3976 81.898C49.0667 76.5079 56.4195 67.7467 62.7428 56.8553C69.0661 45.9639 73.0194 35.2509 74.379 26.8124C75.0606 22.5818 75.0622 19.0933 74.5051 16.5033ZM80.6412 27.7982C79.1303 37.1764 74.8416 48.6215 68.2317 60.0067C61.6217 71.3918 53.7989 80.8079 46.3873 86.7982C42.6904 89.7861 38.961 92.0314 35.4195 93.1708C31.8777 94.3102 28.1762 94.4386 24.9938 92.6115C21.8115 90.7843 20.0725 87.5323 19.2939 83.9124C18.5154 80.2927 18.6061 75.9582 19.3597 71.2805C20.8706 61.9023 25.1593 50.4572 31.7692 39.072C38.3792 27.6869 46.202 18.2708 53.6136 12.2805C57.3105 9.29265 61.0399 7.04729 64.5814 5.90793C68.1232 4.76852 71.8247 4.64011 75.0071 6.46725C78.1894 8.29439 79.9284 11.5464 80.707 15.1663C81.4855 18.786 81.3948 23.1205 80.6412 27.7982Z" fill="#61DAFB"/>
</svg>
)
export default SvgComponent
