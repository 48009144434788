import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={21}
    fill="none"
    {...props}
  >
    <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path
        fill="#4385F4"
        d="M17.64 10.746c0-.638-.058-1.251-.164-1.84H9v3.48h4.843a4.14 4.14 0 0 1-1.796 2.718v2.258h2.909c1.702-1.567 2.684-3.874 2.684-6.615Z"
      />
      <path
        fill="#36A755"
        d="M9 19.542c2.43 0 4.467-.805 5.956-2.18l-2.909-2.258c-.806.54-1.837.859-3.047.859-2.345 0-4.329-1.583-5.036-3.711H.957v2.332A8.995 8.995 0 0 0 9 19.542Z"
      />
      <path
        fill="#FBBB00"
        d="M3.964 12.252a5.407 5.407 0 0 1-.283-1.71c0-.594.103-1.17.283-1.71V6.5H.957A8.988 8.988 0 0 0 0 10.541c0 1.454.347 2.827.957 4.043l3.007-2.332Z"
      />
      <path
        fill="#EA4539"
        d="M9 5.122c1.321 0 2.507.454 3.44 1.346l2.581-2.581C13.463 2.433 11.425 1.542 9 1.542A8.996 8.996 0 0 0 .957 6.5l3.007 2.33C4.671 6.706 6.655 5.122 9 5.122Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .542h17.64v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
