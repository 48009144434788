import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="157"
    height="175"
    viewBox="0 0 157 175"
    fill="none"
  >
    <path
      d="M44.8718 87.7649C33.8691 81.4795 22.3516 75.7407 9.98151 72.9918C8.26374 72.5282 6.4758 72.3812 4.70529 72.5578C3.81917 72.6582 2.96948 72.9669 2.22574 73.4586C1.48201 73.9504 0.865436 74.6112 0.426485 75.387C-0.619098 77.5893 0.426497 80.2096 1.74554 82.2511C4.72143 86.7361 9.17718 90.0154 13.6651 92.9893C25.9548 101.139 39.1774 107.779 52.3517 114.385C50.7431 105.014 47.3973 96.9438 44.8718 87.7649Z"
      fill="#0080FF"
    />
    <path
      d="M129.227 112.005C132.814 124.141 137.157 136.246 144.203 146.775C145.124 148.298 146.312 149.643 147.709 150.746C148.42 151.285 149.248 151.648 150.126 151.804C151.004 151.961 151.907 151.907 152.76 151.646C155.044 150.794 156.122 148.19 156.556 145.779C157.522 140.49 156.556 135.04 155.382 129.784C152.165 115.413 147.178 101.475 142.224 87.6025C136.948 95.5276 133.747 103.678 129.227 112.005Z"
      fill="#0080FF"
    />
    <path
      d="M44.5039 88.6607C44.5039 88.6607 45.3726 172.814 92.633 174.47C139.893 176.126 141.872 89.2715 141.872 88.6607C141.872 88.6607 136.29 76.106 93.1959 76.6043C50.1018 77.1026 44.5039 88.6607 44.5039 88.6607Z"
      fill="#0080FF"
    />
    <path
      d="M118.442 132.232C118.109 136.794 116.43 141.155 113.619 144.763C110.807 148.371 106.988 151.064 102.646 152.502C98.3032 153.939 93.6318 154.057 89.2224 152.839C84.813 151.622 80.8637 149.124 77.8741 145.662C74.8846 142.2 72.9889 137.929 72.4271 133.389C71.8652 128.85 72.6624 124.245 74.7176 120.159C76.7729 116.072 79.994 112.687 83.9734 110.431C87.9529 108.175 92.5121 107.151 97.0741 107.486C103.186 107.942 108.868 110.803 112.874 115.442C116.88 120.081 118.882 126.119 118.442 132.232Z"
      fill="#263238"
    />
    <path
      d="M115.361 132.014C115.072 135.968 113.616 139.746 111.179 142.872C108.741 145.998 105.432 148.331 101.669 149.576C97.9055 150.821 93.8577 150.922 90.0372 149.866C86.2166 148.81 82.795 146.645 80.2048 143.645C77.6147 140.644 75.9724 136.943 75.4857 133.009C74.9989 129.075 75.6896 125.086 77.4704 121.544C79.2512 118.003 82.042 115.069 85.49 113.114C88.9381 111.159 92.8885 110.27 96.8416 110.561C99.4674 110.75 102.03 111.455 104.383 112.636C106.736 113.818 108.832 115.452 110.553 117.444C112.273 119.437 113.583 121.75 114.409 124.25C115.234 126.75 115.558 129.389 115.361 132.014Z"
      fill="white"
    />
    <g clip-path="url(#clip0_4265_6783)">
      <mask
        id="mask0_4265_6783"
        maskUnits="userSpaceOnUse"
        x="83"
        y="126"
        width="17"
        height="17"
      >
        <path
          d="M83.5859 126.221H99.7648V142.394H83.5859V126.221Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4265_6783)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M84.3686 128.616L85.9389 127.045C86.9773 126.007 88.6742 126.007 89.7076 127.045L98.9417 136.275C99.9801 137.313 99.9801 139.01 98.9417 140.048L97.3715 141.613C96.3331 142.652 94.6362 142.652 93.6028 141.613L84.3686 132.384C83.3302 131.346 83.3302 129.649 84.3686 128.616Z"
          fill="#0080FF"
        />
      </g>
      <mask
        id="mask1_4265_6783"
        maskUnits="userSpaceOnUse"
        x="89"
        y="118"
        width="14"
        height="13"
      >
        <path
          d="M89.1758 118.125H102.143V130.469H89.1758V118.125Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_4265_6783)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M90.4719 122.759L94.2405 118.991C95.1017 118.129 96.5149 118.129 97.3811 118.991L101.15 122.759C102.016 123.625 102.016 125.039 101.15 125.9L97.3811 129.668C96.5149 130.535 95.1017 130.535 94.2405 129.668L90.4719 125.9C89.6057 125.039 89.6057 123.625 90.4719 122.759Z"
          fill="#0080FF"
        />
      </g>
      <mask
        id="mask2_4265_6783"
        maskUnits="userSpaceOnUse"
        x="96"
        y="125"
        width="13"
        height="12"
      >
        <path
          d="M96.9375 125.179H108.027V136.961H96.9375V125.179Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_4265_6783)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M97.7935 131.017L102.504 126.306C103.127 125.683 104.145 125.683 104.768 126.306L107.534 129.072C108.157 129.69 108.157 130.708 107.534 131.331L102.823 136.042C102.2 136.665 101.182 136.665 100.559 136.042L97.7935 133.276C97.1704 132.658 97.1704 131.64 97.7935 131.017Z"
          fill="#0080FF"
        />
      </g>
    </g>
    <path
      d="M44.5352 87.4424H44.7925L45.5003 87.6835L48.2349 88.648L50.2135 89.3553C50.9374 89.5965 51.8221 89.8215 52.5942 90.0948C54.3154 90.5931 56.2457 91.2522 58.4495 91.7827L61.8597 92.6507C63.0661 92.9562 64.3208 93.1812 65.6076 93.4545C68.1975 94.0493 70.9965 94.4512 73.9402 94.9334C86.7431 96.7736 99.7435 96.7736 112.546 94.9334C115.474 94.4512 118.289 94.0493 120.863 93.4545C122.166 93.1812 123.42 92.9562 124.611 92.6507L128.037 91.7827C130.241 91.2683 132.171 90.6092 133.876 90.0948L136.273 89.3553L138.236 88.648L140.986 87.6835L141.694 87.4424H141.951L141.71 87.5388L141.002 87.8121L138.284 88.8409L136.321 89.5804L133.941 90.3359C132.219 90.8664 130.289 91.5416 128.101 92.072L124.675 92.9723C123.485 93.2777 122.214 93.5027 120.927 93.7921C118.337 94.4029 115.522 94.8209 112.578 95.3032C99.7397 97.1678 86.6985 97.1678 73.8597 95.3032C70.916 94.8209 68.1171 94.4029 65.5272 93.7921C64.2243 93.5027 62.9695 93.2777 61.7792 92.9723L58.353 92.072C56.1492 91.5416 54.2189 90.8664 52.5138 90.3359C51.6612 90.0627 50.9051 89.8215 50.133 89.5643L48.1706 88.8409L45.4521 87.8121L44.7443 87.5388C44.5352 87.4906 44.5352 87.4585 44.5352 87.4424Z"
      fill="#263238"
    />
    <g opacity="0.3">
      <path
        d="M44.6953 87.5231C44.6953 87.5231 50.2772 75.9489 93.3874 75.4667C136.498 74.9844 142.063 87.5231 142.063 87.5231C142.063 87.5231 98.5831 108.951 44.6953 87.5231Z"
        fill="black"
      />
    </g>
    <path
      d="M59.5246 147.419C59.5246 147.419 59.5246 147.419 59.4442 147.291L59.2351 146.921L58.4469 145.442C58.286 145.12 58.0929 144.767 57.8999 144.365C57.7069 143.963 57.5138 143.545 57.2886 143.079C56.8543 142.147 56.3717 141.086 55.8892 139.864L55.1171 137.983C54.8758 137.324 54.6183 136.633 54.3449 135.925C53.798 134.495 53.3315 132.935 52.7363 131.328C51.7229 128.113 50.7417 124.416 49.873 120.574C49.0044 116.732 48.3771 113.002 47.8623 109.626C47.3476 106.251 47.0097 103.196 46.8167 100.608C46.688 99.3223 46.6076 98.1488 46.5433 97.1199C46.4789 96.0911 46.4306 95.2231 46.3984 94.4997V92.8922V92.3135C46.4017 92.3616 46.4017 92.41 46.3984 92.4581V92.8922C46.3984 93.2941 46.3984 93.8567 46.5111 94.4997C46.6237 95.1427 46.6237 96.1072 46.7202 97.136C46.8167 98.1648 46.881 99.3223 47.0258 100.592C47.2671 103.164 47.6693 106.219 48.1518 109.594C48.6344 112.97 49.3422 116.667 50.1787 120.509C51.0151 124.351 52.0286 127.984 53.0098 131.248C53.5567 132.855 54.0071 134.463 54.6184 135.845L55.3583 137.903C55.6157 138.562 55.8731 139.189 56.0983 139.784C56.5648 140.989 57.0473 142.066 57.4655 142.999L58.0447 144.301C58.2377 144.703 58.4146 145.056 58.5594 145.378L59.2833 146.889L59.4763 147.275L59.5246 147.419Z"
      fill="white"
    />
    <path
      d="M82.289 167.658C82.04 167.648 81.7924 167.616 81.5491 167.562C80.8706 167.438 80.1993 167.277 79.5384 167.08C78.558 166.78 77.601 166.41 76.675 165.97C75.5548 165.429 74.4791 164.8 73.4578 164.09C72.431 163.363 71.4677 162.551 70.5785 161.662C69.8497 160.943 69.1775 160.169 68.5678 159.347C68.1585 158.793 67.7773 158.219 67.4256 157.627C67.3004 157.409 67.1877 157.183 67.0879 156.952C67.0879 156.952 67.6831 157.852 68.6965 159.203C70.8672 161.916 73.6145 164.112 76.7394 165.633C77.6468 166.078 78.5817 166.464 79.5384 166.79C81.2435 167.385 82.3051 167.594 82.289 167.658Z"
      fill="white"
    />
    <path
      d="M90.1042 0.0259688C90.1042 0.0259688 48.9082 -0.472357 42.3773 35.1824C35.8464 70.8372 70.7046 71.5123 80.7261 72.1553C85.2624 72.4608 94.9944 72.4929 103.568 72.3483C122.566 72.0107 135.981 68.3455 140.566 50.7271C141.105 48.6634 141.45 46.5537 141.595 44.4257C142.786 25.1676 129.788 -0.938543 90.1042 0.0259688Z"
      fill="#0080FF"
    />
    <path
      d="M119.992 54.3283L60.1365 55.2767C58.639 55.298 57.152 55.0234 55.7609 54.4688C54.3698 53.9142 53.1021 53.0905 52.0304 52.0449C50.9588 50.9994 50.1043 49.7526 49.5161 48.3762C48.9279 46.9997 48.6176 45.5207 48.6029 44.0241C48.5796 42.527 48.8527 41.04 49.4069 39.6489C49.9612 38.2578 50.7853 36.9901 51.832 35.9187C52.8787 34.8473 54.1272 33.9935 55.5055 33.4065C56.8839 32.8194 58.3648 32.5107 59.8631 32.4982L119.719 31.5658C121.217 31.5425 122.705 31.8156 124.097 32.3694C125.489 32.9232 126.757 33.7468 127.829 34.7928C128.902 35.8388 129.756 37.0864 130.343 38.4638C130.931 39.8413 131.24 41.3212 131.252 42.8185C131.276 44.3148 131.002 45.801 130.448 47.1912C129.894 48.5814 129.069 49.8481 128.022 50.9182C126.975 51.9883 125.727 52.8405 124.348 53.4258C122.97 54.0112 121.49 54.3179 119.992 54.3283Z"
      fill="#263238"
    />
    <path
      d="M72.5563 42.8505C72.4186 43.5594 72.0735 44.2115 71.5647 44.7243C71.0559 45.237 70.4063 45.5874 69.6982 45.7311C68.99 45.8747 68.2552 45.8052 67.5866 45.5312C66.9181 45.2573 66.3458 44.7913 65.9424 44.1922C65.539 43.5931 65.3225 42.8878 65.3203 42.1657C65.3182 41.4436 65.5305 40.7371 65.9303 40.1357C66.3302 39.5342 66.8997 39.0648 67.5666 38.7869C68.2335 38.509 68.968 38.4351 69.677 38.5746C70.6256 38.7611 71.4614 39.3161 72.0012 40.1177C72.541 40.9193 72.7406 41.9021 72.5563 42.8505Z"
      fill="white"
    />
    <path
      d="M114.381 42.1894C114.243 42.8983 113.898 43.5504 113.389 44.0631C112.88 44.5759 112.231 44.9263 111.522 45.0699C110.814 45.2136 110.079 45.144 109.411 44.8701C108.742 44.5961 108.17 44.1301 107.767 43.5311C107.363 42.932 107.147 42.2267 107.145 41.5046C107.142 40.7825 107.355 40.076 107.755 39.4745C108.154 38.873 108.724 38.4037 109.391 38.1258C110.058 37.8479 110.792 37.774 111.501 37.9134C112.45 38.1 113.286 38.655 113.825 39.4565C114.365 40.2581 114.565 41.2409 114.381 42.1894Z"
      fill="white"
    />
    <path
      d="M83.0434 41.1774C83.3168 41.1774 83.1721 42.4795 83.9764 44.1674C84.4236 45.1464 85.0829 46.0139 85.9066 46.7073C86.8678 47.551 88.0625 48.0835 89.3329 48.2344C90.6094 48.3141 91.8807 48.0119 92.9844 47.3663C93.9139 46.8204 94.7194 46.0868 95.3491 45.2123C96.4269 43.6048 96.5233 42.4152 96.7968 42.4313C97.0703 42.4473 97.022 42.7528 97.022 43.3636C96.9855 44.2219 96.7603 45.0616 96.3624 45.8231C95.7637 46.977 94.8773 47.957 93.7887 48.6684C92.4433 49.6071 90.8089 50.0397 89.1749 49.8897C87.541 49.7396 86.0128 49.0166 84.8611 47.8486C83.939 46.9593 83.2535 45.8543 82.8664 44.6336C82.5893 43.7836 82.5177 42.8802 82.6573 41.9972C82.7699 41.4507 82.9308 41.1613 83.0434 41.1774Z"
      fill="white"
    />
    <path
      d="M84.8947 2.69531H84.7498H84.3155C83.9294 2.69531 83.3664 2.6953 82.7069 2.8239C77.6176 3.32257 72.648 4.67013 68.0045 6.81056C64.6157 8.38451 61.438 10.3773 58.5459 12.7423C53.3191 17.1081 49.155 22.6053 46.3689 28.8175C45.5239 30.6897 44.809 32.618 44.2295 34.5885C44.0043 35.2797 43.8756 35.8424 43.763 36.196L43.6504 36.614V36.7426C43.6504 36.7426 43.6504 36.7426 43.6504 36.5979L43.7308 36.1799C43.8273 35.7941 43.9238 35.2476 44.1329 34.5724C44.6596 32.5861 45.3317 30.6411 46.1437 28.7532C47.3716 25.9235 48.8919 23.2296 50.6799 20.7156C55.0491 14.6016 60.9521 9.74481 67.7953 6.63373C70.5839 5.34845 73.501 4.36234 76.4978 3.69197C78.5154 3.23986 80.564 2.93896 82.6265 2.79176C83.3504 2.79176 83.9295 2.79176 84.2351 2.79176H84.8303L84.8947 2.69531Z"
      fill="white"
    />
    <path
      d="M44.5336 52.0942C44.269 51.6993 44.0682 51.2654 43.9384 50.8082C43.5665 49.7629 43.2866 48.6871 43.1019 47.5931C42.9249 46.4717 42.8549 45.336 42.8928 44.2013C42.8838 43.73 42.938 43.2597 43.0537 42.8027C43.1663 42.8027 43.0537 44.9568 43.4398 47.6253C43.8258 50.2938 44.6784 52.046 44.5336 52.0942Z"
      fill="white"
    />
    <path
      d="M110.101 29.5387C110.101 29.6351 102.219 29.6994 92.5031 29.6994C82.7872 29.6994 74.8086 29.6994 74.8086 29.5387C74.8086 29.3779 82.6907 29.3779 92.5031 29.3779C102.315 29.3779 110.101 29.4422 110.101 29.5387Z"
      fill="white"
    />
    <path
      d="M80.0502 69.6627C80.0502 69.6627 79.9537 69.6627 79.7606 69.6627L78.8919 69.7431C78.522 69.7431 78.0716 69.7431 77.5408 69.7431C77.0099 69.7431 76.3987 69.7431 75.7231 69.7431C74.1536 69.736 72.5856 69.6448 71.0259 69.4698C69.1165 69.248 67.2208 68.9206 65.3476 68.4892C63.4812 68.0518 61.6394 67.5152 59.8302 66.8817C58.2216 66.2548 56.613 65.6278 55.487 65.0331C54.8918 64.7598 54.361 64.4543 53.8784 64.2132C53.3958 63.9721 53.0098 63.731 52.688 63.5381L51.9481 63.088C51.7872 62.9915 51.6907 62.9272 51.7068 62.9111L51.9803 63.0397L52.7523 63.4255L53.9588 64.0525C54.4414 64.2775 54.9722 64.5508 55.5674 64.8241C56.7738 65.3867 58.2376 65.9976 59.8945 66.5924C61.5513 67.1871 63.4173 67.7337 65.3958 68.1999C67.3744 68.6661 69.2886 68.9715 71.0259 69.1965C72.7632 69.4216 74.3718 69.518 75.707 69.5502C76.3665 69.5502 76.9616 69.5502 77.4925 69.5502H80.018L80.0502 69.6627Z"
      fill="white"
    />
    <path
      d="M130.432 92.6663H130.287L129.821 92.8431L128.035 93.4701C127.263 93.7594 126.298 94.0809 125.188 94.4185C124.078 94.7561 122.791 95.158 121.359 95.5277L119.124 96.1064C118.351 96.3154 117.515 96.4761 116.695 96.669C115.006 97.087 113.172 97.4085 111.258 97.7622C107.093 98.4757 102.894 98.9693 98.6783 99.2411C94.4558 99.4565 90.2252 99.4565 86.0027 99.2411C84.0563 99.1125 82.2064 99.016 80.4852 98.8231C79.6326 98.7267 78.7962 98.6623 77.9919 98.5498L75.7077 98.2444C74.2439 98.0676 72.9409 97.8104 71.7827 97.6014C70.6246 97.3924 69.6433 97.2156 68.839 97.0227L66.9892 96.6208L66.5065 96.5083H66.3457H66.5226L67.0052 96.5887L68.8712 96.9423C69.6755 97.1031 70.6568 97.2799 71.8149 97.4567C72.9731 97.6335 74.2761 97.8747 75.7399 98.0354L78.0241 98.3248C78.8284 98.4373 79.6327 98.4855 80.5174 98.582C82.2386 98.7588 84.0884 98.8392 86.0187 98.9678C89.9115 99.1125 94.1744 99.1285 98.6623 98.8713C102.867 98.5958 107.054 98.1075 111.209 97.4085C113.124 97.0548 114.941 96.7494 116.63 96.3636C117.467 96.1707 118.239 96.0099 119.075 95.817L121.311 95.2383C122.743 94.9008 123.998 94.4989 125.124 94.1774C126.25 93.8559 127.215 93.5665 127.987 93.3093L129.805 92.7306L130.271 92.5859C130.384 92.6663 130.432 92.6502 130.432 92.6663Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_4265_6783">
        <rect
          width="25.2973"
          height="25.2973"
          fill="white"
          transform="translate(82.7285 117.9)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
