import "./global.css";
import "@popperjs/core/dist/umd/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "@fontsource/roboto";
import "@fontsource/roboto/400.css";
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import { persistor, store } from "./src/redux/store";
import { Provider } from "react-redux";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./src/theme";
import { GatsbyBrowser } from "gatsby";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StepPopup from "./src/Components/TestPage/stepPopup";
import { Grid, Typography } from "@mui/material";
import { SkillLogo } from "./src/svg";
import { TourProvider } from "@reactour/tour";
import {
  SnackbarProvider,
  MaterialDesignContent,
  useSnackbar,
} from "notistack";
import styled from "@emotion/styled";

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: "rgb(237, 247, 237)",
      color: "rgb(30, 70, 32);",
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: "rgb(253, 237, 237)",
      color: "rgb(95, 33, 32);",
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: "rgb(255, 244, 229)",
      color: "rgb(102, 60, 0)",
    },
  }));
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <TourProvider steps={[]}>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
            }}
          >
           
              <ThemeProvider theme={theme}>
                {element}
                <StepPopup
                  open
                  onClose={() => {}}
                  disabledIcon
                  title=""
                  showOnlyOnMobile
                >
                  <Grid container py={3}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <SkillLogo />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        color="#37474F"
                        fontWeight={600}
                        textAlign={"center"}
                        py={2}
                        px={3}
                      >
                        Please note that this dashboard is designed for desktop
                        use only. For the best experience, kindly access it
                        using a laptop or desktop computer.
                      </Typography>
                    </Grid>
                  </Grid>
                </StepPopup>
              </ThemeProvider>
          
          </SnackbarProvider>
        </TourProvider>
      </PersistGate>
    </Provider>
  );
};
