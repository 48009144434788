import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={100}
    fill="none"
    {...props}
  >
    <path
      fill="#95BF46"
      d="M48.852 11.586a.567.567 0 0 0-.513-.476c-.213-.018-4.725-.353-4.725-.353s-3.133-3.11-3.477-3.455c-.344-.344-1.016-.239-1.277-.162-.038.011-.684.21-1.753.541-1.047-3.011-2.894-5.78-6.144-5.78-.09 0-.182.004-.274.01C29.765.688 28.619.157 27.63.157c-7.57 0-11.187 9.464-12.32 14.273-2.942.911-5.032 1.559-5.299 1.643-1.642.515-1.694.567-1.91 2.114-.161 1.171-4.457 34.395-4.457 34.395l33.476 6.272 18.138-3.924s-6.367-43.05-6.407-43.344ZM35.255 8.254l-2.832.876.002-.61c0-1.872-.26-3.38-.677-4.574 1.675.21 2.79 2.115 3.507 4.308Zm-5.584-3.937c.466 1.166.768 2.84.768 5.1 0 .115 0 .22-.002.328l-5.85 1.811c1.127-4.347 3.238-6.447 5.084-7.24Zm-2.25-2.13c.328 0 .657.112.972.329-2.426 1.141-5.027 4.016-6.125 9.758l-4.624 1.432c1.286-4.38 4.34-11.518 9.778-11.518Z"
    />
    <path
      fill="#5E8E3E"
      d="M48.338 11.11c-.213-.018-4.725-.353-4.725-.353s-3.133-3.11-3.477-3.455a.86.86 0 0 0-.484-.222l-2.53 51.773 18.136-3.923S48.89 11.88 48.85 11.586a.567.567 0 0 0-.512-.476Z"
    />
    <path
      fill="#fff"
      d="m30.964 21.132-2.236 6.653s-1.96-1.046-4.362-1.046c-3.521 0-3.699 2.21-3.699 2.767 0 3.038 7.92 4.202 7.92 11.32 0 5.6-3.55 9.205-8.34 9.205-5.746 0-8.684-3.577-8.684-3.577l1.538-5.083s3.02 2.593 5.57 2.593c1.665 0 2.343-1.311 2.343-2.27 0-3.963-6.498-4.14-6.498-10.653 0-5.481 3.934-10.786 11.876-10.786 3.06 0 4.572.877 4.572.877Z"
    />
  </svg>
)
export default SvgComponent
