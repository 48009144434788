import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

const steps = createSlice({
  name: "steps",
  initialState: { type: "", isActive: false, from:"" },
  reducers: {
    handleSteps(state, action) {
      state = action.payload;
      return state;
    },
  },
});
export default steps.reducer;

export const { handleSteps } = steps.actions;
