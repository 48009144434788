import * as React from "react"
const SvgComponent = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <g
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.621}
    >
      <path d="M25.627 45.441h23.296M25.627 29.134h23.296M25.627 12.827h23.296M6.99 12.827l2.329 2.33 6.989-6.99M6.99 29.134l2.329 2.33 6.989-6.99M6.99 45.44l2.329 2.33 6.989-6.988" />
    </g>
  </svg>
)
export default SvgComponent
