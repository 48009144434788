import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

const addCandidatePopup = createSlice({
  name: "user",
  initialState: false,
  reducers: {
    handlePopup(state, action) {
      state = action.payload
      return state
    },
 
  },
});
export default addCandidatePopup.reducer;

export const {
    handlePopup
} = addCandidatePopup.actions;
