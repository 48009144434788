import * as React from "react"
const SvgComponent = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={57}
    fill="none"
    {...props}
  >
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.621}
    >
      <path d="M32.614 10.877h16.307M32.614 22.524h16.307M6.99 34.172h41.932M6.99 45.82h41.932M22.13 20.032v-6.663c0-2.61-1.047-3.657-3.68-3.657h-6.64c-2.608 0-3.657 1.048-3.657 3.657v6.64c0 2.632 1.049 3.68 3.658 3.68h6.64c2.631 0 3.68-1.048 3.68-3.657Z" />
    </g>
  </svg>
)
export default SvgComponent
