import { Palette, createTheme } from "@mui/material/styles";

interface CustomPalette extends Palette {
  primaryLight: {
    main: string;
  };

  lightWhite: {
    main: string;
    secondary: string;
  };

  primary: {
    lightGrayColor: string;
    grayColor: string;
    main: string;
    buttonColorPrimary: string;
    whiteColor: string;
  };
  tabHeadingColor: {
    black: string;
    lightBlack: string;
    lightWhite: string;
  };
  tabHeadingTextSize: {
    heading: string;
    subtitle: string;
  };
  tabHeadingFontWeight: {
    main: string;
    secondary: string;
  };
}

interface CustomTheme {
  palette: CustomPalette;
  typography: {
    fontFamily: string;
    fontSize: {
      fontSizePrimary: string;
      TitleFontSizeMd: string;
      TitleFontSizeXs: string;
      HeadingFontSizeMd: string;
      HeadingFontSizeXs: string;
      SubtitleFontSizeXs: string;
      SubtitleFontSize: string;
    };
    fontWeight: {
      headingFontWeight: string;
      subtitleFontWeight: string;
      titleFontWeight: string;
      fontWeightMedium: string;
    };
  };
}

const theme: CustomTheme = createTheme({




  typography: {
    // fontFamily: "Poppins, sans-serif",
    fontFamily: "Roboto, sans-serif",
    fontSize: {
      fontSizePrimary: "16px",
      TitleFontSizeMd: "24px",
      TitleFontSizeXs: "20px",
      HeadingFontSizeMd: "50px",
      HeadingFontSizeXs: "32px",
      SubtitleFontSizeXs: "12px",
      SubtitleFontSize: "14px",
    },
    fontWeight: {
      headingFontWeight: "600",
      subtitleFontWeight: "500",
      titleFontWeight: "600",
      fontWeightMedium: "400",
    },
  },
  palette: {
    primary: {
      main: "#0080FF",
      grayColor: "#999CB0",
      lightGrayColor: "#667085",
      buttonColorPrimary: "#0080FF",
      whiteColor: "#ffffff",
    },
    secondary: {
      main: "#131313",
    },
    primaryLight: {
      main: "#999cb0",
    },

    lightWhite: {
      main: "#ffffff",
      secondary: "#EBF5FF",
    },
    tabHeadingColor: {
      black: "#37474f",
      lightBlack: "#171716",
      lightWhite: "#9FADBF",
    },
    tabHeadingTextSize: {
      heading: "24px",
      subtitle: "16px",
    },
    tabHeadingFontWeight: {
      main: "600",
      secondary: "500",
    },
  },
});

export default theme;

