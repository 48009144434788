import * as React from "react"
const SvgComponent = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <circle cx={21} cy={21} r={21} fill="#0080FF" />
    <path
      fill="#fff"
      d="M12.562 20.366a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414l-5.657-5.657 5.657-5.657a1 1 0 0 0-1.414-1.414l-6.364 6.364Zm16.317-.293h-15.61v2h15.61v-2Z"
    />
  </svg>
)
export default SvgComponent
